import { Options } from 'ky';

import { deserialize, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import {
  CurrentKarmaAction,
  InvitedUserData,
  KarmaActionsByDate,
  KarmaHistoryList,
  KarmaRewardSettings,
  LeaderboardKarma,
  ReferralStats,
  RewardGuides,
  UserKarma,
} from './types';

export const getReferralStats = (options?: Options) => {
  return Api.get('v2/magicid/user/referrals', options).json<ReferralStats>();
};

export const getInvitedUserList = async (options?: Options) => {
  const data = await Api.get('v2/trace/user/referrals-list', options).json<JsonApiObject>();
  return deserialize<InvitedUserData[]>(data);
};

export const getLeaderboard = (options?: Options) => {
  return Api.get('v2/karma/leaderboard/v2', options).json<LeaderboardKarma>();
};

export const getSelfKarma = (options?: Options) => {
  return Api.get('v2/karma/leaderboard/self/v2', options).json<UserKarma>();
};

export const getRewardSetting = (options?: Options) => {
  return Api.get('v2/karma/rewards/settings', options).json<KarmaRewardSettings>();
};

export const DEFAULT_KARMA_HISTORY_LIMIT = 10;

export type GetKarmaActionHistoryParams = {
  date?: string;
  fromDate?: string;
  limit?: number;
  offset?: number;
  filter?: string;
};

export const getKarmaHistory = (
  options?: ApiOptions<{ searchParams?: GetKarmaActionHistoryParams }>,
) => {
  return Api.get('v2/karma/history', {
    searchParams: {
      limit: DEFAULT_KARMA_HISTORY_LIMIT,
      ...options?.searchParams,
    },
    ...options,
  }).json<KarmaHistoryList[]>();
};

export const getCurrentUserKarmaActions = (
  options: ApiOptions<{ searchParams?: { limit?: number } }>,
) => {
  return Api.get('v2/karma/user/actions/history', {
    ...options,
    searchParams: {
      ...options.searchParams,
    },
  }).json<CurrentKarmaAction>();
};

export const getRewardGuides = (options?: ApiOptions<{ searchParams?: { limit?: number } }>) => {
  return Api.get('v2/karma/user/actions', {
    ...options,
    searchParams: {
      ...options?.searchParams,
    },
  }).json<RewardGuides>();
};

export const getActionsByDate = (
  options?: ApiOptions<{ searchParams?: GetKarmaActionHistoryParams }>,
) => {
  return Api.get('v2/karma/history/date', options).json<KarmaActionsByDate>();
};

export const getWeekKarmaUserHistory = (options?: Options) => {
  return Api.get('v2/karma/history/week', options).json<KarmaHistoryList>();
};

export const getMonthKarmaUserHistory = (options?: Options) => {
  return Api.get('v2/karma/history/month', options).json<KarmaHistoryList>();
};
