import { ApiOptions } from '../helpers/api';
import Api from './ApiProvider';

export const subscribeToMagicIdNewsletter = (
  options: ApiOptions<{
    json: {
      email: string;
      name?: string;
      group: 'LAUNCHPAD' | 'MAGIC_STORE';
    };
  }>,
) => {
  return Api.post('v2/magicid/newsletters/subscribe', options).json();
};
