export const NOTIFICATION_STATUS = {
  READ: 'READ',
  NEW: 'NEW',
} as const;

export type NotificationStatusName = keyof typeof NOTIFICATION_STATUS;

export type NotificationStatus = (typeof NOTIFICATION_STATUS)[NotificationStatusName];

export interface NotificationList {
  rows: {
    id: number;
    user_id: number | null;
    title: string;
    message: string;
    type: string;
    status: NotificationStatus;
    url: string | null;
    timestamp: string;
    icon: string | null;
    removed?: boolean;
    params: {
      share: string | null;
      date: string | null;
    };
  }[];
  count: number;
}
