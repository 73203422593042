import { captchaDecorator } from '@shared/helpers/captcha';
import { digestMessageSha256 } from '@shared/helpers/crypto';
import { deserializeJsonApi } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import {
  SelfMembershipSqrLocked,
  StakingDashboard,
  StakingHistory,
  StakingSetting,
  StakingTransaction,
} from './types/staking';
import { getSIWENonce } from './user';

export const createStake = captchaDecorator<
  ApiOptions<{
    json: {
      walletAddress: string;
      settingId: number;
      amount: number;
    };
  }>,
  StakingTransaction
>((options) => Api.post('v2/karma/staking/stake', options).json(), 'stakingStake', true);

export const getStakingSettings = (options?: ApiOptions) => {
  return Api.get('v2/karma/staking/settings', options).json<{
    data: StakingSetting[];
  }>();
};

export const getStakingHistory = (options?: ApiOptions) => {
  return Api.get('v2/karma/staking/history', options).json<StakingHistory>();
};

export const restakeStake = (
  stakeId: number,
  options: ApiOptions<{ json: { message: string; signature: string } }>,
) => Api.post(`v2/karma/staking/stakes/${stakeId}/restake`, options).json();

export const claimStake = (
  stakeId: number,
  options?: ApiOptions<{ json: { message: string; signature: string } }>,
) => Api.post(`v2/karma/staking/stakes/${stakeId}/claim`, options).json();

export const closeStake = (
  stakeId: number,
  options?: ApiOptions<{ json: { message: string; signature: string } }>,
) => Api.post(`v2/karma/staking/stakes/${stakeId}/close`, options).json<any>();

export const getStakingDashboard = () => {
  return Api.get(`v2/karma/staking/staked-total`).json<{ data: StakingDashboard }>();
};

export const getIsCountryWhitelisted = (options?: ApiOptions) =>
  Api.get('v2/karma/staking/terms-and-conditions', options).json<{ meta: { result: boolean } }>();

export const getSelfMembershipSqrLocked = () => {
  return Api.get('v2/karma/staking/user').json<{ data: SelfMembershipSqrLocked }>();
};

type StakingConfirmation = {
  id: string;
} & (
  | {
      confirmed: true;
    }
  | {
      confirmed: false;
      error: string;
    }
);

export const confirmStake = async ({
  tx,
  smartContract,
  amount,
  wallet,
}: {
  tx: string;
  smartContract: string;
  amount: number | string;
  wallet: string;
}) => {
  const ts = Date.now();
  const nonce = await getSIWENonce({});
  const raw = `${tx}+${smartContract}+${amount}+${ts}+${nonce}+${wallet}`;
  const signature = await digestMessageSha256(raw);

  const data = await Api.post('v2/karma/staking/confirmation', {
    json: {
      tx,
      smartContract,
      amount,
      ts,
      nonce,
      wallet,
      signature,
    },
  }).json();

  return deserializeJsonApi<StakingConfirmation>(data);
};
