import qs from 'qs';

import { Blog, Category, StrapiApiOptions, StrapiApiResponse } from '@api';

import Api from './ApiProvider';

const STRAPI_API_OPTIONS = {
  prefixUrl: process.env.NEXT_PUBLIC_STRAPI_PROXY,
  headers: { Authorization: `bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}` },
};

export const getBlogList = (options: StrapiApiOptions = {}, userId?: string) => {
  const params = qs.stringify(options);
  return Api.get(`blogs`, {
    ...STRAPI_API_OPTIONS,
    headers: {
      ...STRAPI_API_OPTIONS.headers,
      ...(userId && { user: userId }),
    },
    searchParams: params,
  }).json<StrapiApiResponse<Blog[]>>();
};

export const getBlogArticle = (slug: string, options: StrapiApiOptions = {}, userId?: string) => {
  const params = qs.stringify(options);
  return Api.get(`blogs/slug/${slug}`, {
    ...STRAPI_API_OPTIONS,
    headers: {
      ...STRAPI_API_OPTIONS.headers,
      ...(userId && { user: userId }),
    },
    searchParams: params,
  }).json<StrapiApiResponse<Blog>>();
};

export const getBlogCategories = () => {
  return Api.get(`categories`, {
    ...STRAPI_API_OPTIONS,
  }).json<StrapiApiResponse<Category[]>>();
};

export const setBlogLike = (blogId: number, userId: string) => {
  return Api.post(`blogs/${blogId}/like/${userId}`, {
    ...STRAPI_API_OPTIONS,
  }).json();
};

export const deleteBlogLike = (blogId: number, userId: string) => {
  return Api.delete(`blogs/${blogId}/like/${userId}`, {
    ...STRAPI_API_OPTIONS,
  }).json();
};
