import { DeserializedData } from '../../helpers/jsonapi';

export interface MembershipSummaryPageSearchParams {
  [key: string]: string | string[] | undefined;
}

export const MEMBERSHIP_HISTORY_STATUS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  PENDING: 'PENDING',
} as const;

export const MEMBERSHIP_HISTORY_NAME: Record<MembershipHistoryStatus, string> = {
  [MEMBERSHIP_HISTORY_STATUS.SUCCESS]: 'Success',
  [MEMBERSHIP_HISTORY_STATUS.FAIL]: 'Fail',
  [MEMBERSHIP_HISTORY_STATUS.PENDING]: 'Pending',
};

export type MembershipHistoryStatusName = keyof typeof MEMBERSHIP_HISTORY_STATUS;

export type MembershipHistoryStatus =
  (typeof MEMBERSHIP_HISTORY_STATUS)[MembershipHistoryStatusName];

export type DeserializedMembershipTransition = DeserializedData<
  'MEMBERSHIP_TRANSITION',
  {
    status: string;
    type: string;
    amount: number;
    note: string;
    date: string;
  }
>;

export const MEMBERSHIP_ACTION_TYPE = {
  ALL_TYPES: 'ALL_TYPES',
  MEMBERSHIP_TIER_UPGRADE: 'MEMBERSHIP_TIER_UPGRADE',
  MEMBERSHIP_TIER_DOWNGRADE: 'MEMBERSHIP_TIER_DOWNGRADE',
  DAILY_REWARD: 'DAILY_REWARD',
  FIXED_REWARD: 'FIXED_REWARD',
  EXTERNAL_SERVICE_REWARD: 'EXTERNAL_SERVICE_REWARD',
  HOT_OFFER_REWARD: 'HOT_OFFER_REWARD',
  HOT_OFFER_PARTNER_REWARD: 'HOT_OFFER_PARTNER_REWARD',
  WITHDRAWAL: 'WITHDRAWAL',
  CUSTOM: 'CUSTOM',
  DEPOSIT_FROM_USER: 'DEPOSIT_FROM_USER',
  STAKING_CLAIM: 'STAKING_CLAIM',
  STAKING_STAKE: 'STAKING_STAKE',
  STAKING_RESTAKE: 'STAKING_RESTAKE',
  STAKING_CLOSE: 'STAKING_CLOSE',
  SQRP_DAILY_REWARD: 'SQRP_DAILY_REWARD',
  POW_REWARD: 'POW_REWARD',
  SQRP_MEMBERSHIP_REWARD: 'SQRP_MEMBERSHIP_REWARD',
  REWARD_POOL: 'REWARD_POOL',
} as const;

export const MEMBERSHIP_ACTION_NAME: Record<MembershipActionType, string> = {
  [MEMBERSHIP_ACTION_TYPE.ALL_TYPES]: 'All types',
  [MEMBERSHIP_ACTION_TYPE.DAILY_REWARD]: 'SQR Daily Bonus',
  [MEMBERSHIP_ACTION_TYPE.SQRP_DAILY_REWARD]: 'SQRp Daily Bonus',
  [MEMBERSHIP_ACTION_TYPE.HOT_OFFER_REWARD]: 'Hot Offer - Earn Reward',
  [MEMBERSHIP_ACTION_TYPE.HOT_OFFER_PARTNER_REWARD]: 'Hot Offer - Referral Reward',
  [MEMBERSHIP_ACTION_TYPE.MEMBERSHIP_TIER_DOWNGRADE]: 'Membership Downgrade',
  [MEMBERSHIP_ACTION_TYPE.MEMBERSHIP_TIER_UPGRADE]: 'Membership Upgrade',
  [MEMBERSHIP_ACTION_TYPE.WITHDRAWAL]: 'Collect rewards',
  [MEMBERSHIP_ACTION_TYPE.FIXED_REWARD]: 'Membership Fixed Rewards',
  [MEMBERSHIP_ACTION_TYPE.CUSTOM]: 'Custom',
  [MEMBERSHIP_ACTION_TYPE.DEPOSIT_FROM_USER]: 'Deposit',
  [MEMBERSHIP_ACTION_TYPE.STAKING_CLAIM]: 'SQR earnings claimed',
  [MEMBERSHIP_ACTION_TYPE.STAKING_CLOSE]: 'SQR earnings transferred',
  [MEMBERSHIP_ACTION_TYPE.STAKING_RESTAKE]: 'SQR restaked',
  [MEMBERSHIP_ACTION_TYPE.STAKING_STAKE]: 'SQR deposited and staked',
  [MEMBERSHIP_ACTION_TYPE.EXTERNAL_SERVICE_REWARD]: 'External Reward',
  [MEMBERSHIP_ACTION_TYPE.POW_REWARD]: 'Magic Board - Task Completed',
  [MEMBERSHIP_ACTION_TYPE.SQRP_MEMBERSHIP_REWARD]: 'Regular Membership Reward',
  [MEMBERSHIP_ACTION_TYPE.REWARD_POOL]: 'Pool Reward',
};

export type MembershipActionTypeName = keyof typeof MEMBERSHIP_ACTION_TYPE;

export type MembershipActionType = (typeof MEMBERSHIP_ACTION_TYPE)[MembershipActionTypeName];

export type GetSelfMembershipHistoryFilterSearchParams = {
  status?: MembershipHistoryStatus;
  type?: MembershipActionType;
  limit?: number;
};

export type GetSelfMembershipHistorySearchParams = {
  limit?: number;
  offset?: number;
} & GetSelfMembershipHistoryFilterSearchParams;

export type MembershipHistoryMultiSearchParams = Omit<
  GetSelfMembershipHistorySearchParams,
  'type'
> & {
  type?: MembershipActionType[];
};

export type DeserializedMembershipAction = DeserializedData<
  'MEMBERSHIP_ACTION',
  {
    status: string;
    amount: number;
    type: MembershipActionType;
    date: string;
    context?: {
      wallet?: string;
      currency?: string;
      periodStartedAt?: string;
    };
  } & (
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.DAILY_REWARD;
        context: {
          periodStartedAt: string;
          extraAmount?: null | number;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.DEPOSIT_FROM_USER;
        context: {
          wallet: string;
          tx: string;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.MEMBERSHIP_TIER_UPGRADE;
        context: {
          fromTier: MembershipTierId;
          toTier: MembershipTierId;
          wallet: string | null;
          expireAt?: string;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.MEMBERSHIP_TIER_DOWNGRADE;
        context: {
          fromTier: MembershipTierId;
          toTier: MembershipTierId;
          wallet: string | null;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.FIXED_REWARD;
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.WITHDRAWAL;
        context: {
          wallet: string;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.HOT_OFFER_REWARD;
        context: {
          offerName: string;
          extraAmount?: null | number;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.HOT_OFFER_PARTNER_REWARD;
        context: {
          currency: string;
          offerName: string;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.POW_REWARD;
        context: {
          offerName: string;
          extraAmount?: null | number;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.CUSTOM;
        context: {
          note?: string;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.STAKING_CLAIM;
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.STAKING_CLOSE;
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.STAKING_RESTAKE;
        context: {
          totalStaked: number;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.STAKING_STAKE;
        context: {
          wallet: string;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.EXTERNAL_SERVICE_REWARD;
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.SQRP_DAILY_REWARD;
        context: {
          periodStartedAt: string;
          extraAmount?: null | number;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.SQRP_MEMBERSHIP_REWARD;
        context: {
          currency: 'SQRp';
          membership: MembershipTierId;
        };
      }
    | {
        type: typeof MEMBERSHIP_ACTION_TYPE.REWARD_POOL;
        context: {
          pool_id: number;
          pool_name?: string;
        };
      }
  )
>;

export type DeserializedMembershipTransitionList = {
  data: DeserializedMembershipAction[];
};

export const MEMBERSHIP_TIER_ID = {
  FREE: 'FREE',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
  PREMIUM_PLUS: 'PREMIUM_PLUS',
} as const;

export const MEMBERSHIP_TIER_ID_NAME: Record<MembershipTierId, string> = {
  FREE: 'Free',
  PLUS: 'Plus',
  PREMIUM: 'Premium',
  PREMIUM_PLUS: 'Premium Plus',
} as const;

export type MembershipTierId = (typeof MEMBERSHIP_TIER_ID)[keyof typeof MEMBERSHIP_TIER_ID];

export const MEMBERSHIP_TIER_SETTING_ID = {
  REWARD_SQR_DAILY: 'REWARD_SQR_DAILY',
  REWARD_KARMA_MULTIPLIER: 'REWARD_KARMA_MULTIPLIER',
  HOT_OFFER_PRIORITY: 'HOT_OFFER_PRIORITY',
  HOT_OFFER_BONUS: 'HOT_OFFER_BONUS',
  BOOST_COMMISSIONS_BONUS: 'BOOST_COMMISSIONS_BONUS',
  LAUNCHPAD_ALLOCATIONS_USDT: 'LAUNCHPAD_ALLOCATIONS_USDT',
  RAFFLES_TICKETS_WEEKLY: 'RAFFLES_TICKETS_WEEKLY',
  AIRDROP_CAMPAIGNS: 'AIRDROP_CAMPAIGNS',
  REWARD_WITHDRAWAL: 'REWARD_WITHDRAWAL',
  REWARD_WITHDRAWAL_LIMIT: 'REWARD_WITHDRAWAL_LIMIT',
  FIXED_REWARD: 'FIXED_REWARD',
} as const;

export type MembershipTierSettingId =
  (typeof MEMBERSHIP_TIER_SETTING_ID)[keyof typeof MEMBERSHIP_TIER_SETTING_ID];

export type DeserializedMembershipTierSetting = DeserializedData<
  'TIER_SETTING',
  {
    key: MembershipTierSettingId;
    name: string;
    multiplier?: number;
    block?: number;
    limit?: number;
    blocked?: boolean;
    percentage_plus?: number;
    percentage?: number;
    amount?: number;
    currency?: string;
    activated?: boolean;
    label?: string;
    highlighted?: boolean;
  }
> & {
  showInPlans?: boolean;
};

export type DeserializedMembershipTier = Omit<
  DeserializedData<
    'TIER',
    {
      name: string;
      description: string;
      lockAmount: number;
    }
  >,
  'id'
> & {
  id: MembershipTierId;
  tierSettings: DeserializedMembershipTierSetting[];
};

export type DeserializedMembershipTierList = DeserializedMembershipTier[];

export interface MembershipDashboard {
  id: number;
  type: 'MEMBERSHIP_DASHBOARD';
  attributes: {
    sqrDailyReward: number;
    sqrDailyRewardBonus: number;
    karma: number;
    karmaBonus: number;
    hotOfferReward: number;
    hotOfferRewardBonus: number;
    unallocatedRewards: number;
    unallocatedRewardsBonus: number;
    sqrBalance: number;
    sqrLocked: number;
    usdt: number;
    usdc: number;
    fixMembershipReward: number;
    deactivationLock?: string;
  };
}

export interface DeserializedMembershipTierUpdateModel {
  id: number;
  type: 'TransactionId';
  attributes: {
    lockAmount: number;
    transactionId: string;
    userUuid: string;
  };
}

export interface DeserializedFreeMembershipTierActivationModel {
  id: number;
  type: 'TIER';
  attributes: {
    expireAt: string;
    key: MembershipTierId;
    name: string;
    description: string;
    amountToLock: number;
    rank: number;
  };
}
