import { captchaDecorator } from '@shared/helpers/captcha';

import Api, { ApiOptions } from './ApiProvider';
import {
  PARTICIPATION_FILTER,
  PROJECT_VALIDATION_STATUS_FILTER,
  ProjectValidationList,
  ProjectValidationListPageSearchParams,
  ProjectValidationListSearchParams,
  PROPOSAL_FILTER,
} from './types';

export const PROJECT_VALIDATION_LIST_PAGE_LIMIT = 10;

export const normalizeProjectValidationSearchParams = ({
  page,
  status,
  search,
}: ProjectValidationListPageSearchParams = {}): ProjectValidationListSearchParams => {
  const normalizedStatus = status?.toLowerCase();
  let statusSearchParams: ProjectValidationListSearchParams = {};

  if (normalizedStatus === PROJECT_VALIDATION_STATUS_FILTER.VOTED) {
    statusSearchParams = {
      participation: PARTICIPATION_FILTER.PARTICIPATED,
    };
  } else if (normalizedStatus === PROJECT_VALIDATION_STATUS_FILTER.ENDED) {
    statusSearchParams = {
      proposal: PROPOSAL_FILTER.ENDED,
    };
  } else if (normalizedStatus === PROJECT_VALIDATION_STATUS_FILTER.ACTIVE) {
    statusSearchParams = {
      participation: PARTICIPATION_FILTER.NOT_PARTICIPATED,
      proposal: PROPOSAL_FILTER.ACTIVE,
    };
  }

  const normalizedPage = (typeof page === 'string' ? Math.max(parseInt(page, 10), 0) : page) || 0;
  const offset = normalizedPage * PROJECT_VALIDATION_LIST_PAGE_LIMIT;

  return {
    ...(search && {
      whereLikeAppName: search,
    }),
    offset,
    limit: PROJECT_VALIDATION_LIST_PAGE_LIMIT,
    ...statusSearchParams,
  };
};

export const getProjectValidationList = (
  options?: ApiOptions<{
    searchParams?: ProjectValidationListSearchParams;
  }>,
) => {
  const optionsWithDefaults = {
    ...options,
    headers: {
      ...options?.headers,
      v2: 'true',
    },
  };
  return Api.get('v2/store/proposals', optionsWithDefaults).json<ProjectValidationList>();
};

export const pingUserAction = captchaDecorator<{ json: { data: any[] } }>(
  (options) => Api.post('v2/trace/user/ping', options).json(),
  'pingUserAction',
);

export const registerUserVote = captchaDecorator(
  (
    options: ApiOptions<{
      json: {
        vote: 1 | 2;
        proposal: string;
        pubKey: string;
        network: string;
        message: string;
        signature: string;
        reason?: string;
      };
      headers?: {
        'x-sign-in'?: 'v2';
      };
    }>,
  ) => Api.post('v2/validation/vote', options).json<{ success: boolean }>(),
  'registerUserVote',
);
