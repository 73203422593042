import { ReviewFeedbackData } from './review';
import { DeserializedStorePageApp } from './store';
import { DeserializedPublicUser } from './user';

export interface OldAppResponse {
  data: OldApp[];
  total_apps: string;
}

export interface OldApp {
  app_banner: string | null;
  app_chain: null;
  app_icon: string | null;
  app_id: string;
  app_name: string | null;
  category_id: number | null;
  category_name: string | null;
  cmc_id: null;
  creator_id: number | null;
  description: string | null;
  developer_name_id: string | null;
  downloads: number | null;
  extended_ratings: string | null;
  id: number;
  iframe_confirm: boolean | null;
  index_hash: string | null;
  is_adaptive: boolean | null;
  is_free: boolean | null;
  is_verified: boolean | null;
  karma: string | null;
  likes: number | null;
  link: string | null;
  next_validate_date: string | null;
  price: null;
  ratings: null;
  release_date: string | null;
  status: string | null;
  subcategory_id: number | null;
  subcategory_name: string | null;
  token_name: null;
  token_price: null;
  token_price_change: null;
  token_symbol: null;
  user_icon: string | null;
  user_id: number | null;
  user_name: string | null;
  validators_score: string | null;
}

export interface DeserializedAppChainData {
  id: number;
  type: 'CHAIN';
  attributes: {
    name: string;
    shortName: string;
    icon: string;
    contractLink: string;
  };
}

export interface DeserializedAppSocialData {
  id: number;
  type: 'SOCIAL';
  attributes: {
    link: string;
    linkSite: string;
  };
}

export interface DeserializedData<Type extends string, Attributes extends object = object> {
  id: number;
  type: Type;
  attributes: Attributes;
}

export type DeserializedAppTagData = DeserializedData<
  'TAG',
  {
    tag: string;
  }
>;

export type DeserializedAppDeveloperData = DeserializedData<
  'DEVELOPER',
  {
    name: string;
    icon: string;
    link: string;
    appsCount: number;
  }
>;

export type DeserializedAppMediaData = DeserializedData<
  'MEDIA',
  {
    file: string;
    number: number;
  }
>;

export type DeserializedAppOfferData = DeserializedData<
  'HOT_OFFER',
  {
    offerId: string;
  }
>;

export type DeserializedAppPrivacyCollected =
  | 'Purchases'
  | 'Device ID'
  | 'Location'
  | 'Name'
  | 'Contact Info'
  | 'Wallet ID'
  | 'App Usage'
  | 'Identifiers'
  | 'Usage Data'
  | 'Email'
  // eslint-disable-next-line @typescript-eslint/ban-types
  | (string & {});

export type DeserializedAppTrustReview = DeserializedData<
  'TRUST_REVIEW',
  {
    title: string;
    text: string;
    userName: string;
    rating: string;
    timestamp: string;
  } & ReviewFeedbackData
> & {
  user: DeserializedPublicUser;
};

export type DeserializedAppReview = DeserializedData<
  'REVIEW',
  {
    title: string;
    text: string;
    userName: string;
    rating: number;
    timestamp: string;
  } & ReviewFeedbackData
> & {
  user: DeserializedPublicUser;
};

export type DeserializedAppSuggestion = DeserializedData<
  'SUGGESTION',
  {
    title: string;
    text: string;
    userName: string;
    timestamp: string;
  }
> & {
  user: DeserializedPublicUser;
};

export interface DeserializedAppData {
  id: number;
  type: 'APP';
  attributes: {
    appLink: string;
    seo?: null | {
      title: string | null;
      description: string | null;
      keywords: string | null;
    };
    userId: number;
    laseProposalId: string;
    category: string;
    subCategory: string;
    googleCategory: string;
    appName: string;
    status: string;
    indexHash: string | null;
    price: string | null;
    privacyPolicy: null;
    utility: string;
    speed: string;
    design: string;
    simplicity: string;
    experience: string;
    performance: string;
    security: string;
    longDescription: string;
    shortDescription: string;
    isVerified: boolean;
    isFree: boolean;
    isHaveNft: boolean;
    isHaveToken: boolean;
    isAdaptive: boolean;
    productionReady: boolean;
    iframeConfirm: boolean;
    contactEmail: string | null;
    link: string;
    appWebsite: string;
    googlePlayLink: null;
    appStoreLink: null;
    desktopAppLink: null;
    extendedRatings: string;
    trustScore: string;
    creatorScore: string;
    defaultTrustScore: number;
    validatorsScore: string;
    likes: number;
    downloads: number;
    rating: string | null;
    ratings: Record<1 | 2 | 3 | 4 | 5 | 'all', number>;
    generalRanking: number;
    createdDate: string;
    releaseDate: string | null;
    verifiedDate: number;
    nextValidateDate: string | null;
    upcomingDate: string | null;
    privacyCollectedData?: DeserializedAppPrivacyCollected[];
    appIcon: string;
    appBanner: string | null;
    devices: Record<'desktop' | 'mobile' | 'tablet' | 'web', boolean>;
    keywords: string;
    tokenId: null;
    categoryRank: number;
    chainRank: null;
    chainName: null;
    allAudits: number;
    introduction: string;
    overview: string;
    benefits: string;
    getStarted: string;
    tokenOverview: string;
    tokenSummary: string;
    tokenWhitepaper: string;
    faq: {
      answer: string;
      question: string;
    }[];
  };
  tags?: DeserializedAppTagData[];
  chains?: DeserializedAppChainData[];
  media: DeserializedAppMediaData[];
  socials?: DeserializedAppSocialData[];
  developer: DeserializedAppDeveloperData;
  hotOffers?: DeserializedAppOfferData[];
  trustReviews?: DeserializedAppTrustReview[];
  recommendedApps?: DeserializedStorePageApp[];
  suggestion?: DeserializedAppSuggestion[];
}

export type DeserializedAppCurrentUserMeta = DeserializedData<
  'USER_ACTIONS',
  {
    liked: boolean;
    voted: boolean;
  }
>;

export type AppValidationVote = DeserializedPublicUser['attributes'] &
  DeserializedPublicUser['id'] & {
    wallet: string;
    choice: 'Yes' | 'No';
    created: string;
  };

export const INTERNAL_VOTING_TYPE = {
  SNAPSHOT: 'SNAPSHOT',
  MAGICSTORE: 'MAGICSTORE',
} as const;

export interface AppValidation {
  id: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  finished: boolean;
  passed: boolean;
  score: number;
  result: {
    yes: number;
    no: number;
  };
  votes: AppValidationVote[];
  count: number;
  voting: keyof typeof INTERNAL_VOTING_TYPE;
}

export interface Token {
  cg_link: string;
  cmc_link: string;
  token_id: number;
  token_name: string;
  smart_chains: SmartChain[];
  token_symbol: string;
  coin_gecko_link: string;
  smart_contract_update: boolean;
  smart_contract_update_confirm: boolean;
}

export interface SmartChain {
  audit_reports: AuditReport[];
  contract_link: string;
  contract_chain: string;
  contract_git_repository: string;
}

export interface AuditReport {
  audit_date: null | string;
  audit_report: string;
  audit_company: null | string;
  audit_report_obj: {
    id: number;
    hash: string;
    name: string;
    size: number;
    type: string;
    access: string;
    userId: number;
    userIds: any[];
    resolutions: Record<string, any>;
    link: string;
  };
}

export interface Translate {
  language: string;
  long_description: string;
  short_description: string;
}

export interface Social {
  site: string;
  media_link: string;
}

export interface Screenshot {
  id: number;
  hash: string;
  name: string;
  size: number;
  type: string;
  access: string;
  userId: number;
  userIds: any[];
  resolutions: Record<string, any>;
  link: string;
}

export interface AppIcon {
  id: number;
  hash: string;
  name: string;
  size: number;
  type: string;
  access: string;
  userId: number;
  userIds: any[];
  resolutions: Record<string, any>;
  link: string;
}

export interface AppListing {
  data: {
    id: string;
    type: string;
    tempId: number;
    attributes: Attributes;
    tokens?: TokenData[];
    socials: SocialData[];
    media: MediaData[];
    tags: TagsData[];
  };
}

export interface Attributes {
  name: string;
  aggregates: {
    allowAppNameChange: boolean;
  };
  appStoreLink: string;
  appType: 'b2b' | 'b2c' | 'b2b2c';
  appLink: string;
  website: string;
  logo?: string;
  shortDescription: string | null;
  description: string | null;
  chainName?: string;
  businessModel: string | null;
  category: number;
  knowledgeBase?: string;
  mediaKit?: string;
  xVerificationMsgSent?: boolean;
  appFunctionalities: string;
  closeFunding: string | null;
  contactAddress: string | null;
  contactEmail: string;
  contactName: string | null;
  contactPhone: string | null;
  desktopAppLink: string;
  devices: Devices;
  desktopApp: string;
  fileHash: string | null;
  googlePlay: string;
  isIframe: boolean;
  investors: string | null;
  isAdaptive: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  keywords: string[];
  mcIntegration: string | null;
  moneyRaised: string | null;
  price: string | null;
  privacyCollectedData: string | null;
  privacyPolicy: string | null;
  prodactionReady: boolean;
  releaseDate: string;
  subCategory: number;
  status: string;
  trackingInfo: string | null;
  upcomingDate: string;
  usersShouldUse: string | null;
  whatAppDoing: string;
}

export interface Devices {
  web: boolean;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}

interface TagsData {
  attributes: {
    tag: string;
  };
  id: number;
  type: 'TAG';
}

interface TokenAttributes {
  name: string;
  symbol: string;
  coinmarketcapLink: string;
  coinGeckoLink: string;
  tokenomicsLink?: string;
}

interface TokenData {
  id: number;
  type: 'TOKEN';
  attributes: TokenAttributes;
  chains: ChainsData[] | [];
}

interface ChainsData {
  id: number;
  type: 'CHAIN';
  attributes: {
    auditReports?: AuditReportData[];
    chain?: string;
    linkToRepo?: string;
    smartContractAddress?: string;
  };
}

interface AuditReportData {
  file: string;
  date: null | string | Date;
  company: null | string;
}

interface SocialData {
  id: number;
  type: string;
  attributes: SocialAttributes;
}

interface SocialAttributes {
  link: string;
}

interface MediaData {
  id: number;
  type: string;
  attributes: MediaAttributes;
}

interface MediaAttributes {
  file: string;
  number: number;
}

export interface ReportType {
  id: string;
  title: string;
  description: string;
}

export interface ReportResponse {
  rows: Record<string, string | number | undefined>[];
  totalCount: number;
}

interface ListingChainData {
  id: number;
  type: string;
  attributes: {
    chain: string;
    smartContractAddress: string;
    auditReports: {
      file: string;
    }[];
  };
}

interface ListingTokenDataResult {
  id: number;
  type: string;
  attributes: {
    name: string | undefined;
    symbol: string | undefined;
    coinmarketcapLink: string | undefined;
    coinGeckoLink: string | undefined;
    tokenomicsLink?: string;
  };
  chains: ListingChainData[];
}

interface ListingSocialData {
  id: number;
  type: string;
  attributes: {
    link: string;
  };
}

interface ListingMediaData {
  id: number;
  type: string;
  attributes: {
    link: string;
  };
}

export interface ListingData {
  id: null | string;
  type: string;
  attributes: {
    name: string;
    logo: string;
    shortDescription: string;
    description: string;
    category: number;
    subCategory: number;
    appType: string;
    prodactionReady: boolean;
    upcomingDate: string | null; // Не уверен, какой тип у data?.step4?.dateToProd
    isMobile: boolean;
    isDesktop: boolean;
    appStoreLink: string | undefined; // Не уверен, какой тип у data?.step4?.appleStore
    googlePlay: string | undefined; // Не уверен, какой тип у data?.step4?.googlePlay
    appLink: string | undefined; // Не уверен, какой тип у data?.step4?.website
    desktopApp: string | undefined; // Не уверен, какой тип у data?.step4?.desctopUrl
    knowledgeBase?: string;
    mediaKit?: string;
  };
  tokens: ListingTokenDataResult[];
  socials: ListingSocialData[];
  media: ListingMediaData[];
  tags: string[];
}

export interface OpenableApp {
  id: number;
  appId: string;
  name: string;
  icon: string;
  validationStatus: string;
  isProductionReady: boolean;
  websiteUrl: string;
  isWebVersionAdaptive: boolean;
  isWebVersionHasIframeSupport: boolean;
  webVersionUrl?: string;
  desktopVersionUrl?: string;
  androidVersionUrl?: string;
  iosVersionUrl?: string;
}

export type ReviewVote = -1 | 0 | 1;

export interface ReviewVoteStats {
  positiveVotes: number;
  negativeVotes: number;
  funnyVotes: number;
  userVote: ReviewVote;
  userFunnyVote: boolean;
  userAppreciated: boolean;
}
