import { Options } from 'ky';

import { NotificationList } from '@api';

import Api from './ApiProvider';

export const getNotifications = (options?: Options) =>
  Api.get('v2/notifications/updates', options).json<NotificationList>();

export const removeNotifications = (options?: Options) =>
  Api.put('v2/notifications/updates/delete', options).json();

export const removeAllNotification = (options?: Options) =>
  Api.put('v2/notifications/updates/delete/all', options).json();

export const removeAllNewStatus = (options?: Options) =>
  Api.put('v2/notifications/updates/read/all', options).json();

export const removeIdArrNewStatus = (options?: Options) =>
  Api.put('v2/notifications/updates/read/some', options).json();
