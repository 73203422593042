import { DeserializedPublicUser, DeserializedStorePageApp, ReviewVote } from './';

export const REVIEW_TYPE = {
  REVIEW: 'review',
  SUGGESTION: 'suggestion',
  TRUST_REVIEW: 'trust-review',
} as const;

export type ReviewTypeName = keyof typeof REVIEW_TYPE;

export type ReviewType = (typeof REVIEW_TYPE)[ReviewTypeName];

export interface ReviewFeedbackData {
  funnyVotes?: number;
  negativeVotes?: number;
  positiveVotes?: number;
  userVote?: ReviewVote; // available for auth user
  userFunnyVote?: boolean; // available for auth user
}

export type ReviewContentReviewData = {
  id: number;
  title: string;
  text: string;
  type: ReviewType;
  timestamp: string;
  trustScore?: number | string | null;
  rating?: number;
  user: DeserializedPublicUser;
  app?: DeserializedStorePageApp;
} & Required<ReviewFeedbackData>;
