import Api, { ApiOptions } from './ApiProvider';
import { ReportResponse, ReportType, ReviewVote, ReviewVoteStats } from './types';
import { ReviewType } from './types/review';
import { typeToUrl } from './user';

export const sendReport = (
  options: ApiOptions<{
    json: {
      appLink?: string;
      reviewId: number;
      reportText: string;
      reportType: string;
    };
  }>,
) => {
  const { appLink } = options.json;
  const otherOptions = options;

  delete otherOptions.json.appLink;

  return Api.post(`v2/store/app/${appLink}/reviews/report`, otherOptions).json<ReportResponse>();
};

export const sendTrustReport = (
  options: ApiOptions<{
    json: {
      appLink?: string;
      reviewId: number;
      reportText: string;
      reportType: string;
    };
  }>,
) => {
  const { reviewId } = options.json;
  const otherOptions = options;

  return Api.post(
    `v2/store/app/${reviewId}/trusted-reviews/report`,
    otherOptions,
  ).json<ReportResponse>();
};

export const getReviewReportTypes = (options?: ApiOptions) =>
  Api.get(`v2/store/app/reviews/report/types`, options).json<ReportType[]>();

export const voteForReview = (
  {
    id,
    type,
  }: {
    id: number;
    type: ReviewType;
  },
  options: ApiOptions<{ json: { vote: ReviewVote } }>,
) => {
  return Api.post(
    `v2/store/application/${typeToUrl[type]}/${id}/vote`,
    options,
  ).json<ReviewVoteStats>();
};

export const likeReview = (
  {
    id,
    type,
  }: {
    id: number;
    type: ReviewType;
  },
  options: ApiOptions<{ json: { funny: boolean } }>,
) => {
  return Api.post(
    `v2/store/application/${typeToUrl[type]}/${id}/funny`,
    options,
  ).json<ReviewVoteStats>();
};
