export enum TabsID {
  Activity = 'Activity',
  Tasks = 'Tasks',
}

enum Symbols {
  sqr = 'SQR',
  USDT = 'USDT',
}

export type Statuses = 'completed' | 'not_completed';

export type Sorts =
  | 'index'
  | 'date_asc'
  | 'date_desc'
  | 'sqr_asc'
  | 'sqr_desc'
  | 'karma_asc'
  | 'karma_desc';

export interface MagicWallCard {
  id: string;
  type: string;
  attributes: MagicWallAttributes;
  offerCategory: Categories[] | Categories;
}

export type MagicWallAttributes = {
  createdAt: Date;
  description: string;
  name: string;
  rewardAmount: number;
  rewardKarma: number;
  rewardSymbol: Symbols;
  tokenRewardDisabled: boolean;
  submissionsCurr: number;
  submissionsLimit: number;
  status: 'APPROVED' | 'PENDING' | 'REJECTED';
};

export type WallRequest = {
  tab?: TabsID;
  category?: number;
  status?: Statuses;
  sort?: Sorts;
  offset?: number;
  limit?: number;
};

export type Meta = {
  availableKarmaReward: number;
  availableSQRReward: number;
  earnedSQR: number | null;
  earnedKarma: number | null;
};

export interface WallResponse {
  data: MagicWallCard[];
  meta: Meta;
}

export type Categories = {
  attributes: {
    key: string;
    name: string;
  };
  id: number;
  type: string;
};

export interface WallCategoriesResponse {
  data: Categories[];
}
