import { Options } from 'ky';

import { deserialize, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import {
  AppPreview,
  ChainWithAppsCount,
  DeserializedStorePage,
  DeserializedStorePageAppsBlock,
  GetStorePageDataParams,
  StoreSearchApp,
} from './types';

export const getStoreChains = (options?: Options) =>
  Api.get('v2/store/meta/chains', options).json<ChainWithAppsCount[]>();

export const getChains = (options?: Options) =>
  Api.get('v2/store/meta/chain', options).json<ChainWithAppsCount[]>();

export const getStorePage = async ({
  searchParams: { page, ...searchParams },
}: ApiOptions<{ searchParams: GetStorePageDataParams }>) => {
  const data = await Api.get(`v2/store/page/${page}`, { searchParams }).json<JsonApiObject>();
  return deserialize<DeserializedStorePage>(data);
};

export const getStoreAppsPreview = (options?: Options) => {
  return Api.get('v2/store/search/preview', options).json<{
    data: AppPreview[];
    total_apps: number;
  }>();
};

export const getSearchStoreApps = (options?: Options) => {
  return Api.get('v2/store/search', options).json<{
    data: StoreSearchApp[];
    total_apps: number;
  }>();
};

export type GetStoreAppListByCategorySearchParams = {
  page: string;
  block: string;
  chain?: string;
};

export const getStoreAppListByCategory = async ({
  searchParams: { page, ...searchParams },
}: ApiOptions<{ searchParams: GetStoreAppListByCategorySearchParams }>) => {
  const normalizedSearchParams = Object.entries(searchParams).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {} as Record<string, any>);
  const data = await Api.get(`v2/store/page/${page}/see-all`, {
    searchParams: normalizedSearchParams,
  }).json<JsonApiObject>();
  const deserialized = deserialize<DeserializedStorePageAppsBlock>(data);

  return deserialized;
};
