import { deserialize, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import { DeserializedTokenData, DeserializedTokenPrice } from './types';

export type GetTokenSearchParams = {
  period?: string;
};

export const getToken = async (
  tokenId: string,
  options?: ApiOptions<{ searchParams?: GetTokenSearchParams }>,
) => {
  const data = await Api.get(`v2/quote/token/${tokenId}`, options).json<JsonApiObject>();

  return deserialize<DeserializedTokenData>(data);
};

export const getTokenChart = async (
  tokenId: string,
  options: ApiOptions<{ searchParams?: GetTokenSearchParams }>,
) => {
  const data = await Api.get(`v2/quote/token/${tokenId}/chart`, options).json<JsonApiObject>();

  return deserialize<DeserializedTokenData>(data);
};

export const getSqrTokenPrice = async (options?: ApiOptions) => {
  const data = await Api.get('v2/quote/token/sqr/price', options).json<JsonApiObject>();

  return deserialize<DeserializedTokenPrice>(data);
};
